import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`
`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function WarehousingPage() {
  return (
    <Layout pageName="industry">
       <SEO
        title="End to End Technology Solutions for Logestics and Warehousing Industry"
        description="Manage your logistics and warehousing business effectively with industry-specific technology solutions such as access control for warehouses and GPS tracking for vehicles. Get your quote today."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Warehousing</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for Warehousing Enterprises</GroceryTitle>
              <p>Leading a warehousing business is all about managing logistics as effectively and efficiently as possible. Modern technological advances have come along, which help to automate many essential processes, and to also just generally make your business easier to operate. Some of these technologies aren’t just warehouse-specific, either; some available business technologies are really advantageous for any organization!</p>
              <p><strong>WYN Technologies</strong> has installed many helpful technologies for manufacturing, production, and warehouse facilities, and we can help your enterprise, too. Whether you’re looking for the right wired business network setup, WiFi access points, VOIP phones, video surveillance systems, tracking technologies, and more – we’ve got you covered!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
       <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Warehouse Businesses</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling, including Fiber Options</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having the right voice & data cable network is important for pretty much any modern business you can think of, and warehousing enterprises are really no exception. Need some remediation of your existing cabling infrastructure? <strong>WYN</strong> can certainly take care of that. Or perhaps you’re in need of having a complete network set up at a new location. We specialize in the design, installation, and implementation of structured cabling networks, racking, and data closet solutions for organizations of all stripes.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>A wired network will get you part of the way, but you also need to pair that with an effective wireless network for your warehousing business. Many of the devices used in most warehouse environments depend upon wireless connectivity, and these essential devices certainly help make your employees more productive and less stressed. <strong>WYN Technologies</strong> has all the needed tools, know-how, and experience for setting up new wireless access points. We can also take advantage of wireless heat mapping technology to help identify and correct any current problem areas or dead spots with your current WiFi coverage.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>If you run a warehouse operation that doesn’t sell directly to consumers, then why would you need a POS system? POS equipment isn’t just for sales; it’s also an incredibly useful tool for inventory management. And taking advantage of a POS system which can connect with those used by your retail partners or other clients is a fantastic way to help streamline order management, supply replenishment, eCommerce connectivity, shipping & logistics, and more. Having the right POS system installed really can make all the difference for your warehouse enterprise. And <strong>WYN</strong> is happy to provide perfect POS solutions for businesses just like yours!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Access Control Technology</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Another essential piece of the puzzle when it comes to security is making sure that access to your facility is both limited and controlled. You need to be able to manage who has access to your building and when, and it’s also nice to have some good, keyless access solutions available. Whether you prefer PIN pads, proximity cards, keychain fobs, mobile device connectivity, or biometric security features, <strong>WYN</strong> can provide and install whatever access control technology configuration you like!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Moves, Adds, & Changes (MAC Services)</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>The warehousing industry is always growing, expanding, and moving. Hopefully, so is your business! Planning a remodel or move to a new facility? <strong>WYN Technologies</strong> can help things go more smoothly by providing de-installation of any legacy technologies, relocating any items that need moving, and also providing new technology installations. We’ve got lots of experience with moves, adds, & changes, and we’re happy to help you with our MAC services, too.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Storage Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p><strong>WYN</strong> has a complete staging facility for getting new equipment set up and ready-to-go, so that the day of installation goes that much smoother. And we understand that you’re a warehouse business, but we also have some specialized warehouse space of our own which features its own secure data closet, video surveillance, and protected access. If you happen to have any specialized equipment storage needs, we’ve got some space available for you. We also encourage you to take advantage of our depot repair services – sometimes choosing a refurbished item is much more cost-effective than having to purchase something brand-new!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
           <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>Tracking Systems for Both Assets and Vehicles</h2>
                  <p>If you provide leadership for a warehouse operation, then you really have a lot of moving parts to try and manage. What if there were some good technological solutions to help you keep track of all those important moving pieces? The good news? There are! And <strong>WYN Technologies</strong> can help you implement these powerful technological tools.</p>
                  <ul>
                    <li>
                    <strong>Asset Tracking with RFID Technology</strong>
                    <p>Businesses like manufacturers, production facilities, warehouses, and even airports use Radio Frequency Identification (RFID) systems to track everything from valuable assets, to equipment, to designated components, to individual parts, to luggage. RFID technology enable you to manage both inventory & logistics for your warehousing enterprise with much greater accuracy & efficiency, without any added labor or staffing needs. <strong>WYN</strong> can help you to explore your best RFID solution options!</p>
                    </li>
                    <li>
                    <strong>Vehicle Tracking with GPS Technology</strong>
                    <p>If you’re like some other warehouse enterprises, you may also have your own set of vehicles used for materials procurement, transportation, delivery, or other business needs. How’d you like to be able to keep better account of things like vehicle location, ETA, mileage costs, and more? With a vehicle GPS tracking system you can, and <strong>WYN</strong> is happy to install such a system for you!</p>
                    </li>
                        </ul>
                </MarkdownContent>
               <MarkdownContent>
                    <h2>Choose WYN Technologies for All Your Warehouse Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At <strong>WYN</strong>, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility – before we ever come out to install your new equipment.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p><strong>WYN Technologies</strong> only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p><strong>WYN</strong> is ultimately here to help your business succeed. We understand how corporate finances work, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, <strong>WYN</strong> is committed to be here for you for the long run. We actually answer the phone whenever you call, and we’ll respond promptly to your needs in a timely manner, too.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started? Let’s Get Connected!</h2>
                <p>Need to make some business technology upgrades for your warehouse facility, or even for multiple facilities? <strong>WYN Technologies</strong> is happy to come out and conduct a free site evaluation & consultation for you. Got questions? Ready to make a plan? Connect with us today, and let <strong>WYN</strong> provide the best business technology solutions for your warehousing enterprise!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
       </div>
      </Section>
    </Layout>
  )
}
export default WarehousingPage
